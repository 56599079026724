// BG Images
export const BG_COVER = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/Cover.jpg?updatedAt=1696444088725g`;
export const BG_COUNTING_DOWN = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/Countdown%20.jpg?updatedAt=1696444088841`;
export const BG_PHOTO_CONTENT = `https://invitato.net/BagusVia/static/cover-bf6b9490b92ff65e6962376bc9586bb9.jpg`;
export const BG_YOUTUBE_LIVE = ``;
export const BG_INSTAGRAM_FILTER = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/COVER%20MITA%20FAUDY.jpg?updatedAt=1696597986214`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/Desktop.jpg?updatedAt=1696444088930`;

// Couple Images
export const IMG_AKAD_COVER = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/Akad.jpg?updatedAt=1696444088766`;
export const IMG_RESEPSI_COVER = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/Resepsi.jpg?updatedAt=1696444092373`;
export const IMG_GIFTCARD_COVER = `https://user-images.githubusercontent.com/26963539/177611563-66b40560-739a-47d0-bf94-19d365301a00.jpg`;
export const IMG_QURAN = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/Ayat.jpg?updatedAt=1696444088771`;
export const IMG_MAN = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/Groom.jpg?updatedAt=1696444088686`;
export const IMG_GIRL = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/Bride.jpg?updatedAt=1696444088844`;
export const IMG_PHOTO_GALLERY = `https://user-images.githubusercontent.com/26963539/177195544-4ff115cd-bee8-42cc-89ac-8f94738f6ae7.jpg`;
export const IMG_COVER_LOVESTORY = `https://user-images.githubusercontent.com/26963539/177195544-4ff115cd-bee8-42cc-89ac-8f94738f6ae7.jpg`;
export const IMG_FOOTER_COVER = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/Footer.jpg?updatedAt=1696444088859`;

// Logo Images
// import ImgLogo from '@/assets/icons/logo.png';
export const IMG_LOGO = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/MONOGRAM%20BLACK-MITA%20FAUDY.png?updatedAt=1696508439091`;
// import ImgLogoInvert from '@/assets/icons/logo-invert.png';
export const IMG_LOGO_INVERT = `https://ik.imagekit.io/q9egoy1h4/mita%20faudy/MONOGRAM%20WHITE-MITA%20FAUDY.png?updatedAt=1696508438728`;

/**
 * Invitato Image Assets
 * @important - DON'T CHANGE THIS CODE
 */
export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};
