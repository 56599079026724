import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';
import WithAnimation from '@components/Common/WithAnimation';

function LeftContent() {
  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
      textShadow={`rgba(0, 0, 0, 0.3) 1px 1px`}
    >
      <Box padding="42px">
        <WithAnimation>
          <Text letterSpacing="4px" fontSize="sm" color="mainColorText">
            THE WEDDING OF
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Heading
            fontFamily="body"
            margin="24px 0"
            fontWeight="normal"
            size="3xl"
            color="mainColorText"
            textTransform="uppercase"
            dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text fontSize="lg" fontStyle="italic" maxWidth="700px" color="mainColorText">
            “And I knew exactly how old Walt Disney’s Cinderella felt when she found her prince.”{' '}
            <br />— Elizabeth Young
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default LeftContent;
